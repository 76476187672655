*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  font-family: 'Inter', serif;
  font-size: 20px;
  color: gainsboro;
  /*min-height: 100vh;*/
  /*min-width: 100vh;*/
}

p {
  line-height: 1.5;
}

.container {
  background-image: url("./images/bkg.jpg");
  background-size: cover;
}

.wrapper {
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1400px;
}

.flex-container {
  padding-top: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
}

.flex-container-footer {
  margin-top: 100px;
  background-color: black;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.flex-container1 {
  display: flex;
  gap: 30px;
  align-items: center;
}

.flex-item1 {
  flex-grow: 4;
  position: relative;
}
.flex-item2 {
  flex-grow: 1;
}

.flex-item1-video {
  flex: 1;
}

.flex-item2-video {
  flex: 1;
}

.pt-10 {
  padding-top: 30px;
}


.row {
  width: auto;
}

h1 {
  font-size: 6rem;
  font-weight: bolder;
}

h2 {
  font-size: 2.5rem;
  font-weight: bold;
}

h3 {
  font-size: 1.5rem;
  font-weight: bold;
}

.mainlogo {
  max-width: 400px;
}

.logo {
  max-width: 400px;
}

.buylogo {
  min-width: 200px;
}

.text-center {
  text-align: center;
}

.flex-item {
  min-width: 100%;
}

.footer {
  padding: 30px;
}

.diceline {
  max-width:100%;
}

.logo-half {
  display: none;
}

.finalDetails-mobile {
  display: none;
}

.video-width {
  max-width: 550px;
}

@media only screen and (max-width: 768px) {
  body {
    width: 100%;
    padding: 0;
  }


  .mainlogo {
    max-width: 75%;
  }

  .logo-half {
    display: block;
  }

  .finalDetails-mobile {
    display: block;
  }

  .logo-item .logo {
    display: none;
  }

  .logo-item .logo-half {
    margin-left: -30px;
    opacity: 0.2;
    /*text-indent: -50%;*/
    /*left: -10px;*/
  }

  .video-width {
    max-width: 100%;
  }

  .diceline {
    display: none;
  }

  .finalDetails {
    display: none;
  }

  .diceline-mobile {
    max-width:100%;
  }

  .flex-container1.no-flex-mobile {
    display: block;
  }

  .flex-container1.kill-gap {
    gap: 0;
  }

  .pt-10-m {
    padding-top: 30px;
  }
}
